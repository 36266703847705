body {
  margin: 0;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

strong {
  color: #1f9847;
}

/* Slider Pagination */
.swiper-pagination-bullet {
  transition-duration: 200ms;
  --swiper-pagination-bullet-height: 2px;
  --swiper-pagination-bullet-width: 30px;
  --swiper-pagination-color: #ffa530;
  --swiper-pagination-bullet-border-radius: 20%;
}
.swiper-pagination-bullet-active {
  --swiper-pagination-bullet-height: 3px;
  --swiper-pagination-bullet-width: 60px;
}
